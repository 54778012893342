// <input
//    data-controller="input-mask"
//    data-input-mask-pattern="00-000-0000"
// >
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.$element.attr('autocomplete', 'off');
    this.$element.mask(this.pattern);
  }

  disconnect() {
    this.$element.unmask('destroy');
  }

  get $element() {
    return $(this.element);
  }

  get pattern() {
    return this.data.get('pattern');
  }
}
