import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'player' ]

  connect() {
    // videojs doesn't pause itself when the modal is hidden
    this.$element.on('hidden.bs.modal.induction', () => {
      if (!this.hasPlayerTarget) {
        return;
      }

      this.playerTarget.pause();
    });
  }

  disconnect() {
    this.$element.off('hidden.bs.modal.induction');
  }

  get $element() {
    return $(this.element);
  }
}
