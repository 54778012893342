class TurbolinksProgressBar {
  start() {
    this.progressBar = Turbolinks.controller.adapter.progressBar;
    this._bindEvents();
  }

  // private
  _bindEvents() {
    document.addEventListener('turbolinks:visit',          this._onTurbolinksVisit.bind(this));
    document.addEventListener('turbolinks:request-start',  this._onTurbolinksRequestStart.bind(this));
    document.addEventListener('turbolinks:request-end',    this._onTurbolinksRequestEnd.bind(this));
    document.addEventListener('turbolinks:load',           this._onTurbolinksLoad.bind(this));
  }

  _onTurbolinksVisit() {
    this.progressBar.show();
    this.progressBar.setValue(0.3);
  }

  _onTurbolinksRequestStart() {
    this.progressBar.setValue(0.5);
  }

  _onTurbolinksRequestEnd() {
    this.progressBar.setValue(1);
    this.progressBar.hide();
  }

  _onTurbolinksLoad() {
    this.progressBar.hide();
  }
}

export default new TurbolinksProgressBar;
