import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "userInput", "formInput" ]

  connect() {
    if (!this.hasUserInputTarget) {
      return;
    }

    let self = this;

    // On load
    self.updateFormInputs(self.$userInputTarget.val());

    // When changed or focusout
    // For datepicker input that cannot use `db.change` event, we need to use focusout event.
    // Because a normal change event doesn't fire when a user picks a date.
    self.$userInputTarget.on("change focusout", function() {
      self.updateFormInputs($(this).val());
    });
  }

  updateFormInputs(selectedInput) {
    let self = this;

    if (selectedInput) {
      this.$formInputTargets.each(function() {
        self.emptyInput($(this));
      });
    }
  }

  emptyInput($element) {
    $element.val("");

    if ($element.is(":checkbox")) {
      $element.attr("checked", null);
    }

    let elementSelectize = $element[0].selectize;
    if (elementSelectize) {
      elementSelectize.clear();
      elementSelectize.clearOptions();
    }
  }

  get $userInputTarget() {
    return $(this.userInputTarget);
  }

  get $formInputTargets() {
    return $(this.formInputTargets);
  }
}
