class ModalAlert {
  constructor(id, title, body) {
    this.id = id;
    this.title = title;
    this.body = body;
  }

  display() {
    this._modalHtml().modal('show');
  }

  _modalHtml() {
    return $(`
      <div id="${this.id}" class="modal" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title font-weight-bold">${this.title}</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">${this.body}</div>

            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    `);
  }
}

export default ModalAlert;
