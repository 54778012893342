/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// https://stackoverflow.com/questions/28969861/managing-jquery-plugin-dependency-in-webpack
// https://makandracards.com/makandra/69621-webpack-how-to-avoid-multiple-versions-of-jquery

// Vendors
import 'material-dashboard';
import 'selectize';
import 'jquery-mask-plugin';
import 'sweetalert2';
import 'rails-sweetalert2-confirm';
import 'cocoon';
import 'jquery-expander';
import 'simplify-scorm';

import Dropzone from "dropzone";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import TurbolinksProgressBar from "utils/turbolinks_progress_bar";
import ModalAlert from "utils/modal_alert";
import FancyMessage from "utils/fancy_message";

// Disable auto discover for all elements:
Dropzone.autoDiscover = false;

// Application
window.App = {};
window.App.ModalAlert = ModalAlert;
window.App.FancyMessage = FancyMessage;

// https://github.com/turbolinks/turbolinks/issues/179,277
// https://github.com/hotwired/turbo/issues/188
// Turbolink seems to handle 404/500 status code differently where it re-evaluates the whole `application.js`.
// It causes exceptions like `rails-ujs has already been loaded!`.
if (!window._rails_loaded) {
  Rails.start();
  Turbolinks.start();
  ActiveStorage.start();

  require('trix');
  require('@rails/actiontext');
  require('local-time').start();
  require('jquery-ui/ui/widgets/sortable');

  TurbolinksProgressBar.start();
}

// https://github.com/turbolinks/turbolinks/issues/146
var dispatchUnloadEvent = function() {
  var event = document.createEvent("Events")
  event.initEvent("turbolinks:unload", true, false)
  document.dispatchEvent(event)
}

addEventListener("beforeunload", dispatchUnloadEvent)
addEventListener("turbolinks:before-render", dispatchUnloadEvent)

import "channels";
import "controllers";
