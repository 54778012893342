import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    // https://github.com/basecamp/trix/issues/55#issuecomment-238966508
    this.element.addEventListener("click", function(event) {
      var el = event.target;

      if (el.tagName === "A" && !el.isContentEditable && el.host !== window.location.host) {
        el.setAttribute("target", "_blank");
      }
    }, true);
  }
}
