import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "requestReason", "estimatedReturnDateContainer" ];

  initialize() {
    this.$requestReasonTarget.selectize({
      copyClassesToDropdown: false
    });
  }

  connect() {
    // toggle estimated return date
    this.toggleEstimatedReturnDateField(this.$requestReasonTarget.val());

    let that = this;
    this.requestReasonTarget.selectize.on('change', function(value) {
      that.toggleEstimatedReturnDateField(value);
    });
  }

  toggleEstimatedReturnDateField(requestReasonValue) {
    let requestReasonText = this.requestReasonTarget.selectize.getItem(requestReasonValue).text();
    this.$estimatedReturnDateContainerTarget.hide();

    if (requestReasonText == "Inactive - On leave of absence") {
      this.$estimatedReturnDateContainerTarget.show();
    }
  }

  get $requestReasonTarget() {
    return $(this.requestReasonTarget);
  }

  get $estimatedReturnDateContainerTarget() {
    return $(this.estimatedReturnDateContainerTarget);
  }
}
