// <input
//    data-controller="datepicker"
// >
import DateTimePickerController from 'controllers/datetimepicker_controller';

export default class extends DateTimePickerController {
  get options() {
    let newOptions = super.options;

    newOptions['format'] = 'MM/DD/YYYY';

    if (this.data.get('disable-future-date')) {
      newOptions['maxDate'] = 'now';

      let selectedDateValue = this.$element.val();
      if (selectedDateValue != '') {
        let selectedDate = new Date(selectedDateValue);
        let currentDate  = new Date();

        if (selectedDate > currentDate) {
          newOptions['maxDate'] = selectedDate;
        }
      }
    }

    return newOptions;
  }
}
