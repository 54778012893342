import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static targets = [ "preview" ]

  connect() {
    let dropzone = new Dropzone(
      this.previewTarget,
      {
        // https://devcenter.heroku.com/articles/direct-to-s3-image-uploads-in-rails
        // https://ryanbigg.com/2017/08/rails-dropzone-js-amazon-s3-and-imgix
        url: this.config.url,
        params: this.config.fields,
        paramName: 'file',
        acceptedFiles: this.config.htmlAccept,
        maxFiles: this.config.maxFiles,
        maxFilesize: this.config.maxSize/1024/1024, // in megabytes

        addRemoveLinks: true,
        timeout: 0, // disable timeout when uploading
        dictDefaultMessage: 'Drop files here or click to upload.',
        dictRemoveFile: "<i class='material-icons'>delete</i>",
        dictMaxFilesExceeded: `You can not upload any more files. Only ${this.config.maxFiles} file(s) is allowed.`,

        // clean filename (removing spaces...) before sending to s3
        // this filename is used as s3 key
        renameFile: function(file) {
          let cleanedName = file.name.replace(/\s/g, '_').replace(/[^\w.-]/gi, '');
          return cleanedName;
        }
      }
    );

    // When the file is uploaded successfully
    dropzone.on('success', (file, s3Response) => {
      let attachment;

      // Normalize file uploaded to s3 and attachment from db
      if (s3Response) {
        let parsedResponse  = $.parseXML(s3Response);
        let $parsedResponse = $(parsedResponse);
        let s3Key           = $parsedResponse.find('Key').text();

        attachment = {
          name: file.name,
          size: file.size,
          s3_key: s3Key,
          mime_type: file.type,
          last_modified_at: null
        };

        // To avoid uploading issue when the File API couldn't report the size,
        // set it zero, and we could figure it out on the backend.
        if (!attachment.size) {
          attachment.size = 0;
        }

        // On ChromeOS, some files don't have type such as: word, excel, ....
        if (!attachment.mime_type) {
          attachment.mime_type = this.mimeTypeFromFileName(file.name);
        }

        // mobile upload doesn't have lastModifiedDate
        if (file.lastModifiedDate) {
          attachment['last_modified_at'] = file.lastModifiedDate.toISOString();
        }
      } else {
        attachment = file;
      }

      // Create hidden fields to append the form for submission
      let now = Date.now();
      let allowedFields = ['id', 'name', 'size', 's3_key', 'mime_type', 'last_modified_at', '_destroy'];
      let $container = $("<div>", { class: "row" });

      for (let key in attachment) {
        if (allowedFields.indexOf(key) == -1) {
          continue;
        }

        let inputOptions = {
          type: "hidden",
          name: `${this.config.formParamPrefix}[${now}][${key}]`,
          value: attachment[key]
        };
        inputOptions['data-role'] = `attachment_${key}`;

        let $input = $("<input />", inputOptions);
        $container.append($input);
      }
      $(document).trigger("direct_upload_attachment.uploadSucceeded", $container);

      // Attach the container to file object so that we could reference it later
      file.$attachmentElement = $container;

      let $previewElement = $(file.previewElement);
      if (attachment['_destroy']) {
        // Remove it since it has been marked for destruction (when the validation is failed)
        $previewElement.remove();
        this.previewTarget.classList.remove('dz-started');
      }
      else {
        // Add the dz-success class (the green tick sign)
        $previewElement.addClass('dz-complete');
        $previewElement.addClass('dz-clickable');
        $previewElement.addClass('dz-success');

        // Since the url need to be signed from our server.
        // The user could see it only after refresh.
        if (attachment.url) {
          $previewElement.wrapInner(`<a class="dz-open-download" href="${attachment.url}" target="_blank"></a>`);
        }
      }
    });

    // When the file is removed
    dropzone.on('removedfile', (file) => {
      let $attachmentElement = file.$attachmentElement;
      if (!file.$attachmentElement) {
        return;
      }

      // Set the value to `true` to mark for destruction (existing record)
      // Remove the element (new record)
      let $attachmentDestroy = $attachmentElement.find('[data-role="attachment__destroy"]');
      if ($attachmentDestroy[0]) {
        $attachmentDestroy.val('true');
      }
      else {
        $attachmentElement.remove();
      }
    });

    // 1. Disable the submit button
    // 2. Need to send 'Content-Type' to S3.
    //    Otherwise, Invalid according to Policy: Policy Condition failed: ["starts-with", "$Content-Type", ""]
    dropzone.on("sending", (file, xhr, formData) => {
      formData.append("Content-Type", file.type);
      $(document).trigger("direct_upload_attachment.uploadStarted", this.element);
    });

    // Enable the submit button back when all are cancelled (no more queues & uploading)
    dropzone.on("canceled", (file) => {
      if (dropzone.getQueuedFiles() == 0 && dropzone.getUploadingFiles() == 0) {
        $(document).trigger("direct_upload_attachment.uploadCanceled", this.element);
      }
    });

    // Enable the submit button back when all have completed
    dropzone.on("queuecomplete", () => {
      $(document).trigger("direct_upload_attachment.uploadCompleted", this.element);
    });

    // Load existing attachments
    // https://stackoverflow.com/questions/24009298/dropzone-js-display-existing-files-on-server
    this.config.existingAttachments.forEach(function(attachment) {
      attachment.type = attachment.mime_type;
      attachment.accepted = true;

      // Don't push this file, since it has been marked as destroyed.
      // If we push, the validation will go wrong and the UI will be confused upon page refreshed.
      if (!attachment._destroy) {
        dropzone.files.push(attachment);
      }

      dropzone.emit("addedfile", attachment);
      dropzone.emit("success",   attachment, null);
      dropzone.emit("complete",  attachment);
    });
  }

  disconnect() {
    if (this.previewTarget && this.previewTarget.dropzone) {
      this.previewTarget.dropzone.destroy();
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  mimeTypeFromFileName(fileName) {
    let extName = fileName.split('.').pop();

    return this.defaultMimeTypes[extName] || "application/octet-stream";
  }

  defaultMimeTypes() {
    return {
      "doc":  "application/msword",
      "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "rft":  "application/rtf",
      "txt":  "text/plain",

      "xls":  "application/vnd.ms-excel",
      "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "xlsm": "application/vnd.ms-excel.sheet.macroEnabled.12",
      "csv":  "text/csv",

      "pps":  "application/vnd.ms-powerpoint",
      "ppsx": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
      "ppt":  "application/vnd.ms-powerpoint",
      "pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",

      "pdf":  "application/pdf",

      "bmp":  "image/bmp",
      "jpg":  "image/jpeg",
      "jpeg": "image/jpeg",
      "png":  "image/png",

      "mp3":  "audio/mpeg",

      "mp4":  "video/mpeg",
      "avi":  "video/x-msvideo",
      "mpg":  "video/mpeg",
      "mpeg": "video/mpeg",
      "mkv":  "video/x-matroska",
      "wmv":  "video/x-ms-wmv"
    };
  }

  get config() {
    return JSON.parse(this.data.get("config"));
  }
}
