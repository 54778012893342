import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "globalPicker", "checkboxItem", "actionContainer", "actionDescription", "selectAll", "clearSelection" ]

  checkItem(event) {
    let checked = $(event.target).prop("checked");
    if (checked) {
      return;
    }

    this.$actionContainerTarget.hide();
    this.checkboxAllRecordsController.recordIdsValue = [];
  }

  checkAll(event) {
    let checked = $(event.target).prop("checked");

    this.setAllCheckboxes(checked);

    if (this.hasSelectAllTarget && this.hasClearSelectionTarget) {
      this.$selectAllTarget.show();
      this.$clearSelectionTarget.hide();
    }
  }

  unCheckAll() {
    $(this.globalPickerTarget).prop("checked", false);
    this.setAllCheckboxes(false);
  }

  setAllCheckboxes(checked) {
    this.checkboxItemTargets.forEach(function(el) {
      $(el).prop("checked", checked);
    });

    if (this.hasSelectAllTarget && this.hasClearSelectionTarget) {
      if (checked) {
        let selectedLength = this.getCheckedValues().length;
        this.$actionContainerTarget.show();
        this.$actionDescriptionTarget.html(`All ${selectedLength} records on this page are selected.`);
        this.$clearSelectionTarget.hide();
      } else {
        this.$actionContainerTarget.hide();
      }
    }
  }

  getCheckedValues() {
    let values = [];
    this.checkboxItemTargets.forEach(function(el) {
      let $el = $(el);

      if ($el.prop("checked")) {
        values.push($el.val());
      }
    });

    return values;
  }

  get checkboxAllRecordsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "checkbox-all-records"
    );
  }

  get $actionContainerTarget() {
    return $(this.actionContainerTarget);
  }

  get $actionDescriptionTarget() {
    return $(this.actionDescriptionTarget);
  }

  get $selectAllTarget() {
    return $(this.selectAllTarget);
  }

  get $clearSelectionTarget() {
    return $(this.clearSelectionTarget);
  }
}
