import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "currentContainer", "previousContainer" ]

  connect() {
    if (!this.hasPreviousContainerTarget) {
      return;
    }

    // $removedItem expects to have:
    // [data-new-record], [data-affiliation-fields-target="endDate"], [data-affiliation-fields-target="current"]
    let $previousContainerTarget = $(this.previousContainerTarget);
    this.$currentContainerTarget
      .on("cocoon:after-remove", (event, $removedItem) => {
        // Do nothing for new record
        if ($removedItem.data('new-record')) {
          return;
        }

        // Prepare the endDate and Move existing record to previous container
        // endDate
        let currentDate       = new Date();
        let currentDateString = currentDate.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
        let $endDate          = $removedItem.find('[data-affiliation-fields-target="endDate"]');
        $endDate.removeAttr('readonly');
        $endDate.attr('data-controller', 'datepicker');
        $endDate.val(currentDateString);

        // current
        let $current = $removedItem.find('[data-affiliation-fields-target="current"]');
        $current.val('0');

        // destroy
        let $destroy = $removedItem.find('[name$="[_destroy]"]');
        $destroy.val('0');

        // $removedItem
        $removedItem.appendTo($previousContainerTarget);
        $removedItem.show();

        // hide X button within view if a past record can't be marked for destruction
        // checkout AgencyAffiliation#can_destroy?
        if ($removedItem.data('can-mark-for-destruction') == false) {
          let $removeButton = $removedItem.find('[data-role="cocoon-fields.removeButton"]');
          $removeButton.hide();
        }
      });
  }

  disconnect() {
    this.$currentContainerTarget.off("cocoon:after-remove");
  }

  get $currentContainerTarget() {
    return $(this.currentContainerTarget);
  }
}
