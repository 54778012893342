import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // Hide sidebars which are empty
    this.$element.find("ul.nav:not(:has(*))").closest(".link_section").hide();

    // Set active sidebar
    let $active_link = this.$element.find(".collapse li.active");
    if ($active_link.length == 0) {
      return
    }

    let $link_section = $active_link.closest("li.link_section")
    $link_section
      .addClass("active")
      .find(".collapse:first")
      .addClass("show");
    $link_section
      .find("[aria-expanded=\"false\"]")
      .attr("aria-expanded", "true");

    // Nested navs
    let $nav = $link_section.parents(".nav");
    if ($nav.length > 1) {
      $nav.first().parent(".collapse").addClass("show");
    }
  }

  get $element() {
    return $(this.element);
  }
}
