// <div
//    data-controller="full-page-background"
//    data-full-page-background-url=image_path("material-dashboard/login.jpg")
// >
// </div>
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (!this.backgroundImageUrl) {
      return;
    }

    let $element = $(this.element);
    $element.css('background-image', `url('${this.backgroundImageUrl}')`);
  }

  get backgroundImageUrl() {
    return this.data.get('url');
  }
}
