import { Controller } from '@hotwired/stimulus';

// This controller usually works with checkbox-list controller to retreive selected records
// and display modal for those selected records
export default class extends Controller {
  static targets = [ "tooltip" ]

  showModal(event) {
    event.preventDefault();

    let $button         = $(event.target);
    let buttonUrl       = $button.prop("href");
    let selectedRecords = this.checkboxListController.getCheckedValues();
    let params          = $.param({ selected_records: selectedRecords });

    buttonUrl += (buttonUrl.indexOf('?') >= 0 ? '&' : '?') + params;

    if (selectedRecords.length === 0) {
      this.showAlert();
      return false;
    } else {
      $.get(buttonUrl);
    }
  }

  get checkboxListController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "checkbox-list");
  }

  showAlert() {
    let $tooltipTarget = $(this.tooltipTarget);
    $tooltipTarget.tooltip("show");

    window.setTimeout(function () { $tooltipTarget.tooltip("hide"); }, 1500);
  }
}
