import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    series: Array,
    colors: Array
  }

  connect() {
    this.chart = new ApexCharts(this.element, {
      series: this.seriesValue,
      chart: {
        type: 'donut',
      },
      fill: {
        colors: this.colorsValue
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
        active: {
          filter: {
            type: 'none',
          }
        }
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          donut: {
            size: '50%'
          }
        }
      },
      grid: {
        padding: {
          bottom: -80
        }
      },
      legend: {
        show: false
      }
    });
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }
}
