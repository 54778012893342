import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "questionType", "answerChoicesContainer", "addAnswerChoice", "removeAnswerChoice", "answerChoiceInput" ]

  initialize() {
    this.onLoad();
  }

  connect() {
    this.$questionTypeTarget.on("change.alludo", () => {
      this.onChange();
    });
  }

  onLoad() {
    if (this.questionTypeTargetValue == "Question::Paragraph") {
      this.hideAnswerChoices();
      this.removeAllAnswerChoices();
    }
    else {
      this.showAnswerChoices();
    }
  }

  onChange() {
    if (this.questionTypeTargetValue == "Question::Paragraph") {
      this.hideAnswerChoices();
      this.removeAllAnswerChoices();
    }
    else if (this.questionTypeTargetValue == "Question::LikertWithComment") {
      this.showAnswerChoices();
      this.removeAllAnswerChoices();

      this.$addAnswerChoiceTarget.click();
      this.$addAnswerChoiceTarget.click();
      this.$addAnswerChoiceTarget.click();
      this.$addAnswerChoiceTarget.click();
      this.$addAnswerChoiceTarget.click();
      this.addAnswerChoice(-1, "Strongly disagree");
      this.addAnswerChoice(-2, "Disagree");
      this.addAnswerChoice(-3, "Neither agree nor disagree");
      this.addAnswerChoice(-4, "Agree");
      this.addAnswerChoice(-5, "Strongly agree");
    }
    else {
      this.showAnswerChoices();
    }
  }

  addAnswerChoice(index, text) {
    // Cannot use answerChoiceInputTargets (dynamic)
    this.$answerChoicesContainerTarget.find('[data-question-fields-target="answerChoiceInput"]').eq(index).val(text);
  }

  hideAnswerChoices() {
    this.$addAnswerChoiceTarget.hide();
    this.$answerChoicesContainerTarget.hide();
  }

  showAnswerChoices() {
    this.$addAnswerChoiceTarget.show();
    this.$answerChoicesContainerTarget.show();
  }

  removeAllAnswerChoices() {
    this.$removeAnswerChoiceTargets.each(function() {
      $(this).click();
    });
  }

  get questionTypeTargetValue() {
    return this.$questionTypeTarget.val();
  }

  get $questionTypeTarget() {
    return $(this.questionTypeTarget);
  }

  get $answerChoicesContainerTarget() {
    return $(this.answerChoicesContainerTarget);
  }

  get $addAnswerChoiceTarget() {
    return $(this.addAnswerChoiceTarget);
  }

  get $removeAnswerChoiceTargets() {
    return $(this.removeAnswerChoiceTargets);
  }
}
