// Required targets: `container`, `containerItem`, `add`
// Optional targets: `inputFocus`, and `inputSeq`
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "container", "containerItem", "add" ]

  connect() {
    if (!this.hasAddTarget) {
      return;
    }

    this.$addTarget.data("association-insertion-method", "append");
    this.$addTarget.data("association-insertion-node", () => {
      return this.$containerTarget;
    });

    // Focus the element on the insertedItem
    this.$containerTarget
      .on("cocoon:after-insert", (event, $insertedItem) => {
        let $inputFocus = $insertedItem.find('[data-cocoon-fields-target="inputFocus"]');
        let $inputSeq   = $insertedItem.find('[data-cocoon-fields-target="inputSeq"]');

        $inputSeq.val(this.containerItemTargets.length);
        $inputFocus.focus();
      });
  }

  disconnect() {
    this.$containerTarget.off("cocoon:after-insert");
  }

  get $containerTarget() {
    return $(this.containerTarget);
  }

  get $addTarget() {
    return $(this.addTarget);
  }
}
