import { Controller } from '@hotwired/stimulus';

// This controller usually works with checkbox-list controller to retreive all record ids across the page
// and do a POST request on those.
export default class extends Controller {
  static targets = [ "tooltip", "selectAll", "clearSelection", "actionContainer", "actionDescription" ]
  static values = { recordIds: Array }

  makePost(event) {
    event.preventDefault();

    let $button   = $(event.target);
    let buttonUrl = $button.prop("href");

    let selectedRecords = [];
    if (this.recordIdsValue.length > 0) {
      selectedRecords = this.recordIdsValue;
    } else if (this.manualSelectedRecords.length > 0) {
      selectedRecords = this.manualSelectedRecords;
    }

    if (selectedRecords.length == 0) {
      this.showAlert();
      return false;
    }
    else {
      $.post(buttonUrl, { selected_records: selectedRecords });
    }
  }

  setRecordIds(event) {
    event.preventDefault();
    let self = this;

    $.get(self.selectAllTarget.href).done(function(data) {
      let ids = $.map(data, function(item) { return item.candidate_id; });
      self.recordIdsValue = ids;

      self.$actionDescriptionTarget.html(`All ${self.recordIdsValue.length} records are selected.`);
      self.$selectAllTarget.hide();
      self.$clearSelectionTarget.show();
    });
  }

  clearRecordIds(event) {
    event.preventDefault();
    this.$selectAllTarget.show();

    this.recordIdsValue = [];
    this.checkboxListController.unCheckAll();
  }

  showAlert() {
    let $tooltipTarget = $(this.tooltipTarget);
    $tooltipTarget.tooltip("show");

    window.setTimeout(function () { $tooltipTarget.tooltip("hide"); }, 1500);
  }

  get checkboxListController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "checkbox-list"
    );
  }

  get manualSelectedRecords() {
    return this.checkboxListController.getCheckedValues();
  }

  get $selectAllTarget() {
    return $(this.selectAllTarget);
  }

  get $clearSelectionTarget() {
    return $(this.clearSelectionTarget);
  }

  get $actionDescriptionTarget() {
    return $(this.actionDescriptionTarget);
  }

  get $actionContainerTarget() {
    return $(this.actionContainerTarget);
  }
}
