// <form action=root_path data-controller="turbo-form">
//   <select data-turbo-form-target="element"></select>
// </form>
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "element" ]

  connect() {
    this.$elementTarget.on("change.induction", function() {
      let $form    = $(this.form);
      let visitUrl = $form.attr("action") + "?" + $form.serialize();

      Turbolinks.visit(visitUrl);
    });
  }

  disconnect() {
    this.$elementTarget.off("change.induction");
  }

  get $elementTarget() {
    return $(this.elementTarget);
  }
}
