import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "collapse", "collapseButton" ];

  connect() {
    if (typeof(Storage) === "undefined") {
      console.log("No web storage Support.");
    }

    this.localStorage   = window.localStorage;
    let $collapseTarget = $(this.collapseTarget);
    let $collapseButtonTarget = $(this.collapseButtonTarget);

    // prevents further propagation of the current event for nested controllers
    $collapseTarget.on("shown.bs.collapse", (event) => {
      this.write("show");
      event.stopPropagation();
    });

    $collapseTarget.on("hidden.bs.collapse", (event) => {
      this.write("hide");
      event.stopPropagation();
    });

    let state = this.read() || "";
    if (state == "hide") {
      $collapseTarget.removeClass("show");
      // by default, the collapse icon is keyboard_arrow_up
      $collapseButtonTarget.attr("aria-expanded", "false");
    }
  }

  write(value) {
    this.localStorage.setItem(this.storageIdentifier, value);
  }

  read() {
    return this.localStorage.getItem(this.storageIdentifier);
  }

  get storageIdentifier() {
    return this.data.get("storageIdentifier");
  }
}
