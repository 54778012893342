import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "dateOfBirthInput", "planDropdown", "previewContainer" ]

  connect() {
    let self = this;
    let $dateOfBirthInput = $(this.dateOfBirthInputTarget);
    let $planDropdown = $(this.planDropdownTarget);
    let $previewContainer = $(this.previewContainerTarget);

    // Cannot use `dp.change` from datepicker
    // Otherwise, that event will be fired too after the form is submitted.
    $dateOfBirthInput.on("focusout", (event) => {
      let selectedDate = event.target.value;
      if (!selectedDate) {
        self.updateSelectizeOptions($planDropdown, []);
        $previewContainer.html("");
        return;
      }

      let urlParams = new URLSearchParams();
      urlParams.append('filter[date_of_birth]', selectedDate);

      $.ajax({
        url: `/university_unit/plans?${urlParams.toString()}`,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
          let options = $.map(data, function(plan, index) {
            return `<option value="${plan['gid']}">${self.escapeHtml(plan['name'])}</option>`;
          });
          self.updateSelectizeOptions($planDropdown, options);
          if (!$planDropdown.val()) {
            $previewContainer.html("");
          }
        }
      });
    });

    // On load so that it will populate available plans
    $dateOfBirthInput.trigger("focusout");

    // When the plan changes, update the preview
    $planDropdown.on("change", function(event) {
      let selectedValue = event.target.value;
      if (!selectedValue) {
        $previewContainer.html("");
        return;
      }

      $.ajax({
        url: `/university_unit/plans/${selectedValue}`,
        type: 'GET',
        dataType: 'json',
        success: function(plan) {
          let lis = [];
          lis.push(`<li>Email: ${plan.email}</li>`);
          lis.push(`<li>Agency: ${plan.agency_names.join(', ')}</li>`);

          if (plan.participation_year !== null) {
            lis.push(`<li>Participation Year: ${plan.participation_year}</li>`);
          }
          if (plan.enrollment_type !== null) {
            lis.push(`<li>Enrollment Type: ${plan.enrollment_type}</li>`);
          }
          if (plan.year_1_ilp_complete !== null) {
            lis.push(`<li>Year 1 Complete: ${plan.year_1_ilp_complete}</li>`);
          }
          if (plan.induction_program_completed !== null) {
            lis.push(`<li>Induction Complete: ${plan.induction_program_completed}</li>`);
          }

          $previewContainer.html(lis.join("\n"));
        }
      });
    });
  }

  // https://stackoverflow.com/questions/24816/escaping-html-strings-with-jquery
  escapeHtml(text) {
    return $("<div>").text(text).html();
  }

  updateSelectizeOptions($element, options) {
    // destroy selectize
    if($element[0].selectize) {
      $element[0].selectize.destroy();
    }

    // update options
    if(options) {
      $element.html(options);
    }
    else {
      $element.html('');
    }

    // init selectize back
    this.initSelectize($element);

    // preselect after selectize initial
    let preselectedValue = $element.data('preselected-value');
    if (preselectedValue) {
      $element[0].selectize.setValue(preselectedValue);
    } else {
      $element[0].selectize.clear();
    }
  }

  initSelectize($element) {
    $element.selectize({copyClassesToDropdown: false});
  }
}
