import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "input" ]

  // https://stackoverflow.com/questions/24622432/add-select-all-option-to-simple-form-association-collections
  connect() {
    let self = this;
    this.$element.find('[data-checkall-target="input"][value=0]').on('change', function() {
      var checked = this.checked;

      self.$inputTargets.each(function() {
        $(this).prop('checked', checked);
      });
    });
  }

  get $element() {
    return $(this.element);
  }

  get $inputTargets() {
    return $(this.inputTargets);
  }
}
