// <input
//    data-controller="datetimepicker"
// >
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.$element.attr('autocomplete', 'off');
    this.$element.datetimepicker(this.options);
  }

  disconnect() {
    if (this.$element.data('DateTimePicker')) {
      this.$element.data('DateTimePicker').destroy();
    }
  }

  get $element() {
    return $(this.element);
  }

  get options() {
    return {
      useCurrent: false,
      timeZone: 'Pacific Time (US & Canada)',
      icons: {
        time: 'fa-regular fa-clock',
        date: 'fa-regular fa-calendar',
        up: 'fa-regular fa-chevron-up',
        down: 'fa-solid fa-chevron-down',
        previous: 'fa-solid fa-chevron-left',
        next: 'fa-solid fa-chevron-right',
        today: 'fa-solid fa-screenshot',
        clear: 'fa-solid fa-trash',
        close: 'fa-solid fa-remove',
        inline: true
      }
    };
  }
}
