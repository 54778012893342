import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    sortDirection: { type: String, default: "asc" }
  }

  connect() {
    let controller = this;

    this.$element.selectize({
      copyClassesToDropdown: false,
      valueField: controller.valueField,
      labelField: controller.labelField,
      searchField: controller.searchField,
      sortField: [
        { field: controller.labelField, direction: this.sortDirectionValue },
        { field: '$score' }
      ],
      load: function(query, callback) {
        if (!query.length) return callback();
        if (!controller.remoteUrl) return callback();

        $.ajax({
          url: controller.buildSearchURL(query),
          type: 'GET',
          dataType: 'json',
          error: function() {
            callback();
          },
          success: function(res) {
            callback(res.slice(0, 10));
          }
        });
      }
    });
  }

  disconnect() {
    if (this.element.selectize) {
      this.element.selectize.destroy();
    }
  }

  buildSearchURL(query) {
    let urlParts  = this.remoteUrl.split('?');
    let urlParams = new URLSearchParams(urlParts[1]);
    urlParams.append('filter[query]', query);

    return `${urlParts[0]}?${urlParams.toString()}`;
  }

  get $element() {
    return $(this.element);
  }

  // Live fetching from dom element
  get remoteUrl() {
    return this.$element.data('selectize-remote-url');
  }

  get valueField() {
    return this.data.get('value-field') || 'id';
  }

  get labelField() {
    return this.data.get('label-field') || 'name';
  }

  get searchField() {
    return this.data.get('search-field') || 'name';
  }
}
