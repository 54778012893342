import { Controller } from '@hotwired/stimulus';

// Quite similar to checkbox_custom_text_controller.js
export default class extends Controller {
  static targets = [ "input", "customInput" ]

  connect() {
    if (!this.hasCustomInputTarget) {
      return;
    }

    if (this.selectedItems.indexOf(this.customLabel) == -1) {
      this.$customInputTarget.addClass("d-none");
    } else {
      this.$customInputTarget.addClass("d-show");
    }

    let self = this;
    this.$inputTarget.on("change", function() {
      let selectedLabel = $(this).find('option:selected').text();

      if (selectedLabel == self.customLabel) {
        self.$customInputTarget.removeClass("d-none");
        self.$customInputTarget.addClass("d-show");
        self.$customInputTarget.focus();
      }
      else {
        self.$customInputTarget.removeClass("d-show");
        self.$customInputTarget.addClass("d-none");
      }
    });
  }

  get customLabel() {
    return "Other";
  }

  get $inputTarget() {
    return $(this.inputTarget);
  }

  get $customInputTarget() {
    return $(this.customInputTarget);
  }

  get selectedItems() {
    return Array.from(this.inputTarget.selectedOptions).map(selectedOption => selectedOption.text);
  }
}
