import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    seriesColors: Array,
    xaxisColors: Array,
    yaxisColors: Array,
    series: Array,
    categories: Array,
    animationEnabled: { type: Boolean, default: true },
    chartWidth: { type: String, default: "100%" }
  }

  connect() {
    this.chart = new ApexCharts(this.element, {
      series: this.seriesValue,
      xaxis: {
        categories: this.categoriesValue,
        labels: {
          show: true,
          style: {
            colors: this.xaxisColorsValue
          },
          formatter: function(value, timestamp, opts) {
            let number = value.toString().split(' ')[0]; // Eg: 1.2 Connecting learning to students

            return number;
          }
        }
      },
      yaxis: {
        min: 0,
        max: 5,
        tickAmount: 5,
        labels: {
          offsetX: 0,
          offsetY: 15,
          show: true,
          style: {
            colors: this.yaxisColorsValue
          }
        }
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            return value;
          }
        },
        y: {
          show: true,
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            if (value >= 1 && value < 2) {
              return value + ' Beginning';
            }
            else if (value >= 2 && value < 3) {
              return value + ' Emerging';
            }
            else if (value >= 3 && value < 4) {
              return value + ' Developing';
            }
            else if (value >= 4 && value < 5) {
              return value + ' Refining';
            }
            else {
              return value + ' Modeling';
            }
          }
        }
      },
      chart: {
        animations: {
          enabled: this.animationEnabledValue
        },
        width: this.chartWidthValue,
        height: 350,
        type: 'radar',
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      colors: this.seriesColorsValue,
      stroke: {
        width: 2
      },
      fill: {
        opacity: 0.1
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: '#a9a9a9',
            strokeWidth: 1,
            connectorColors: '#a9a9a9'
          }
        }
      }
    });
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }
}
