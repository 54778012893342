import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // https://www.w3schools.com/jquery/tryit.asp?filename=tryjquery_eff_animate_smoothscroll
  click(event) {
    let target = event.target;

    // Make sure target.hash has a value before overriding default behavior
    if (target.hash == "") {
      return;
    }

    // Prevent default anchor click behavior
    event.preventDefault();

    // Store hash
    var hash = target.hash;

    // Using jQuery's animate() method to add smooth page scroll
    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
    $("html, body").animate({
      scrollTop: $(hash).offset().top
    }, 800, function() {

      // Add hash (#) to URL when done scrolling (default click behavior)
      window.location.hash = hash;
    });
  }

  beforeVisit(event) {
    window.isTurbolinksRequest = true;
  }

  connect(event) {
    if (window.isTurbolinksRequest) {
      this.scrollIntoView();
    }
  }

  scrollIntoView(event) {
    let hash    = document.location.hash;
    if (!hash) {
      return;
    }

    let element = document.querySelector(hash);
    if (element) {
      let hashName  = hash.substring(1, hash.length);
      let $collapse = $(`#collapse_${hashName}`);
      if ($collapse["collapse"] && $collapse.data("smooth-scroll-on-load")) {
        let $parentCollapse = $collapse.parents(".collapse");
        if ($parentCollapse["collapse"]) {
          $parentCollapse.addClass("show");
        }

        $collapse.addClass("show");
      }

      element.scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}
