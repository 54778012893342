import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "input", "customInput" ]

  connect() {
    if (!this.hasCustomInputTarget) {
      return;
    }

    if (this.selectedItems.indexOf(this.customLabel) == -1) {
      this.$customInputTarget.addClass("d-none");
    } else {
      this.$customInputTarget.addClass("d-show");
    }

    let self = this;
    this.$inputTargets.on("change", function() {
      let checked = this.checked;
      let label   = $(this).parent().text();

      if (label == self.customLabel) {
        if (checked) {
          self.$customInputTarget.removeClass("d-none");
          self.$customInputTarget.addClass("d-show");
          self.$customInputTarget.focus();
        }
        else {
          self.$customInputTarget.removeClass("d-show");
          self.$customInputTarget.addClass("d-none");
        }
      }
    });
  }

  get customLabel() {
    return "Other";
  }

  get $inputTargets() {
    return $(this.inputTargets);
  }

  get $customInputTarget() {
    return $(this.customInputTarget);
  }

  get selectedItems() {
    return $('[data-checkbox-custom-text-target="input"]:checked').map((i, material) => $(material).parent().text()).toArray();
  }
}
