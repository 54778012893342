import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $(this.fileElement).on('change.bs.fileinput', () => {
      this.remove();
    });
  }

  disconnect() {
    $(this.fileElement).off('change.bs.fileinput');
  }

  remove() {
    if (this.fileValue) {
      this.removeFileElement.value = '0';
    } else {
      this.removeFileElement.value = '1';
    }
  }

  get fileValue() {
    return this.fileElement.value;
  }

  get removeFileElement() {
    return this.targets.find('remove_file');
  }

  get fileElement() {
    return this.targets.find('file');
  }
}
