import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    datasets: Array
  }

  connect() {
    let categories = $.map(this.datasetsValue, val => val[0]);
    let series     = $.map(this.datasetsValue, val => val[1]);

    this.chart = new ApexCharts(this.element, {
      series: [{
        data: series
      }],
      labels: categories,
      yaxis: {
       labels: {
          show: true,
          align: 'left',
          maxWidth: 250
        }
      },
      chart: {
        type: 'bar',
        offsetX: -25,
        height: 450
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: false
      }
    });
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }
}
