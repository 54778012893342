import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    selectedText: String,
    selectedTexts: Array
  }
  static targets = [ "input", "togglingInput" ]

  connect() {
    let self = this;
    if (!this.hasInputTarget) {
      return;
    }

    // on load
    self.togglingInputs(self.selectedInputTargetValue);

    // when changed
    this.$inputTargets.on("change", function () {
      let selectedText;
      if (this["type"] == "checkbox") {
        if ($(this).is(":checked")) {
          selectedText = this.value;
        }
      } else {
        selectedText = this.value;
      }

      self.togglingInputs(selectedText);
    });
  }

  // input could be radio/select
  get $inputTargets() {
    return $(this.inputTargets);
  }

  get selectedInputTargetValue() {
    let selected = this.inputTargets.filter(e => e.checked);
    if (selected.length > 0) {
      return selected[0].value;
    }
    else {
      return this.$inputTargets.val();
    }
  }

  togglingInputs(selectedText) {
    if (selectedText == this.selectedTextValue ||
      this.selectedTextsValue.includes(selectedText)) {
      this.showTogglingInputTargets();
    }
    else {
      this.hideAndEmptyTogglingInputTargets();
    }
  }

  showTogglingInputTargets() {
    this.togglingInputTargets.forEach(function(el) {
      $(el).parents(".form-group").show();
    });
  }

  hideAndEmptyTogglingInputTargets() {
    this.togglingInputTargets.forEach(function(el) {
      if (el.selectize) {
        el.selectize.setValue("");
      } else if (el["type"] == "checkbox") {
        let nameAttr = el["name"];
        $(`[name='${nameAttr}']`).val("");
      }
      $(el).val("");

      $(el).parents(".form-group").hide();
    });
  }
}
