import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.$element.expander({
      slicePoint: 300,
      expandEffect: 'slideDown',
      expandSpeed: 0,
      collapseEffect: 'slideUp',
      collapseSpeed: 0,
    });
  }

  disconnect() {
    this.$element.expander('destroy');
  }

  get $element() {
    return $(this.element);
  }
}
