// Required these targets: inputSeq`
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'inputSeq' ]

  connect() {
    let self = this;

    this.$element.sortable({
      placeholder: "sortable-drop",
      forcePlaceholderSize: true,
      handle: ".seq-sortable-handle",
      helper: function (e, ui) {
        ui.children().each(function () {
          $(this).width($(this).width());
        });

        return ui;
      },
      update: function (event, ui) {
        // Update the server immediately
        if (self.updateUrl) {
          $.ajax({
            type: 'PATCH',
            url: self.updateUrl,
            data: $(this).sortable('serialize', { key: self.serializeKey }),
          });
        }
        // Update the seq hidden fields
        else {
          self.inputSeqTargets.forEach(function (inputSeq, index) {
            $(inputSeq).val(index + 1);
          });
        }
      }
    });
  }

  disconnect() {
    if (this.$element.sortable('instance')) {
      this.$element.sortable('destroy');
    }
  }

  get $element() {
    return $(this.element);
  }

  get updateUrl() {
    return this.data.get('update-url');
  }

  get serializeKey() {
    return this.data.get('serialize-key');
  }
}
