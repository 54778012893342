import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "from", "to" ]

  connect() {
    // http://eonasdan.github.io/bootstrap-datetimepicker/#linked-pickers
    let $fromTarget = $(this.fromTarget);
    let $toTarget   = $(this.toTarget);

    $fromTarget.on("dp.change", function (e) {
      if ($toTarget.data("DateTimePicker")) {
        $toTarget.data("DateTimePicker").minDate(e.date);
      }
    });

    $toTarget.on("dp.change", function (e) {
      if ($fromTarget.data("DateTimePicker")) {
        $fromTarget.data("DateTimePicker").maxDate(e.date);
      }
    });
  }
}
