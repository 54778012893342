import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.childHtmls = this.$childElements.map(function($childElement) {
      return $childElement.html();
    });

    // Init selectize only after grabbing inner_html above
    // otherwise, there will be race conditions (because selectize empty those inner_html).
    this.initSelectize(this.$parentElement);
    $.each(this.$childElements, (index, $childElement) => {
      this.initSelectize($childElement);
    });

    // Update children on load when the parentTarget is present
    if (this.$parentElement[0]) {
      this.updateChildren();
    }
  }

  connect() {
    this.$parentElement.on('change.induction', () => {
      this.updateChildren();
      this.clearChildren();
    });
  }

  disconnect() {
    this.$parentElement.off('change.induction');
  }

  filterChildren() {
    let escapedselectedTexts = this.getSelectedTexts();

    let allChildOptions = [];
    $.each(this.$childElements, (index, $childElement) => {
      $.each(escapedselectedTexts, (_, escapedselectedText) => {
        let childOptions = $(this.childHtmls[index]).filter(`optgroup[label='${escapedselectedText}']`).html();
        allChildOptions.push(childOptions);
      });

      let flattenChildOptions = $.map(allChildOptions, (childOptions) => childOptions);
      this.updateSelectizeOptions($childElement, flattenChildOptions);
    });
  }

  restoreChildren() {
    $.each(this.$childElements, (index, $childElement) => {
      let childOptions = $(this.childHtmls[index]);

      this.updateSelectizeOptions($childElement, childOptions);
    });
  }

  clearChildren() {
    $.each(this.$childElements, (index, $childElement) => {
      $childElement[0].selectize.clear();
    });
  }

  updateChildren() {
    if (this.getSelectedTexts()) {
      this.filterChildren();
    }
    else {
      this.restoreChildren();
    }
  }

  getSelectedTexts() {
    return $(`#${this.parentElementId} :selected`).toArray().map(item => {
      return item.text.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    });
  }

  initSelectize($element) {
    $element.selectize({copyClassesToDropdown: false});
  }

  updateSelectizeOptions($element, options) {
    // destroy selectize
    if($element[0].selectize) {
      $element[0].selectize.destroy();
    }

    // update options
    if(options) {
      $element.html(options);
    }
    else {
      $element.html('');
    }

    // init selectize back
    this.initSelectize($element);

    // preselect after selectize initial
    let preselectedValue = $element.data('preselected-value');
    if (preselectedValue) {
      $element.val(preselectedValue);
    } else {
      $element[0].selectize.clear();
    }
  }

  get $parentElement() {
    return $(this.targets.find('parent'));
  }

  get parentElementId() {
    return this.$parentElement[0].id;
  }

  get $childElements() {
    return this.targets.findAll('child').map(function(child, index) {
      return $(child);
    });
  }
}
