import { Controller } from '@hotwired/stimulus';

// https://www.betterstimulus.com/interaction/callbacks
// This controller usually works with direct_upload_attachment controller.
// It subscribes to those events
// to disable/enable submit buttons and append the attachments to the form.
export default class extends Controller {
  static targets = [ "form", "submit" ]

  connect() {
    this.dropzoneUploadings = {};

    $(document).on("direct_upload_attachment.uploadSucceeded", (event, $container) => {
      this.$formTarget.append($container);
    });

    $(document).on("direct_upload_attachment.uploadStarted", (event, dropzoneElement) => {
      this.dropzoneUploadings[dropzoneElement.getAttribute("id")] = true;
      this.$submitTargets.attr("disabled", true);
    });

    $(document).on("direct_upload_attachment.uploadCanceled direct_upload_attachment.uploadCompleted", (event, dropzoneElement) => {
      this.dropzoneUploadings[dropzoneElement.getAttribute("id")] = false;

      // Remove duplicated
      let array = [...new Set(Object.values(this.dropzoneUploadings))];

      // Make sure all uploadings are finished.
      // We can't compare array with array (it doesn't return correct result).
      if (JSON.stringify(array) == JSON.stringify([false])) {
        this.$submitTargets.attr("disabled", false);
      }
    });
  }

  get $formTarget() {
    return $(this.formTarget);
  }

  get $submitTargets() {
    return $(this.submitTargets);
  }
}
