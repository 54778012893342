// Required targets: `container`
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    // $removedItem expects to have:
    // [data-new-record]
    this.$containerTarget
      .on("cocoon:before-remove", (event, $removedItem) => {
        // Do nothing for new record or statutory requirement record
        if ($removedItem.data('new-record') || $removedItem.data('statutory-requirement-record')) {
          return;
        }

        var confirmation = confirm("You are about to delete this record. This will delete all of its dependent records as well. Are you sure you want to delete?");
        if( confirmation === false ) {
          event.preventDefault();
        }
      });
  }

  disconnect() {
    this.$containerTarget.off("cocoon:before-remove");
  }

  get $containerTarget() {
    return $(this.containerTarget);
  }
}
