import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "userRoles",
    "jobTitle", "phoneNumber", "mailingAddress",
    "agencyRepresentativeFieldsContainer", "reflectiveCoachFieldsContainer"
  ]

  connect() {
    let selectedRoles = this.$userRolesTarget.val();

    // toggle agency_representative fields
    this.toggleAgencyRepresentativeFields(selectedRoles);

    // toggle reflective_coach fields
    this.toggleReflectiveCoachFields(selectedRoles);

    this.$userRolesTarget.selectize({
      copyClassesToDropdown: false
    });

    // display ARs/RCs fields
    let that = this;
    this.userRolesTarget.selectize.on('change', function(values) {
      that.toggleAgencyRepresentativeFields(values);
      that.toggleReflectiveCoachFields(values);
    });
  }

  disconnect() {
    this.userRolesTarget.selectize.destroy();
  }

  toggleAgencyRepresentativeFields(values) {
    this.$agencyRepresentativeFieldsContainerTarget.hide();
    this.$jobTitleTarget.prop("disabled", true);
    this.$phoneNumberTarget.prop("disabled", true);
    this.$mailingAddressTarget.prop("disabled", true);
    this.$sosPointOfContactTarget.prop("disabled", true);

    if (values.includes("agency_admin") || values.includes("agency_designee")) {
      this.$jobTitleTarget.prop("disabled", false);
      this.$phoneNumberTarget.prop("disabled", false);
      this.$mailingAddressTarget.prop("disabled", false);
      this.$sosPointOfContactTarget.prop("disabled", false);
      this.$agencyRepresentativeFieldsContainerTarget.show();
    }
  }

  toggleReflectiveCoachFields(values) {
    this.$reflectiveCoachFieldsContainerTarget.hide();

    if (values.includes('reflective_coach')) {
      this.$reflectiveCoachFieldsContainerTarget.show();
    }
  }

  get $userRolesTarget() {
    return $(this.userRolesTarget);
  }

  get $jobTitleTarget() {
    return $(this.jobTitleTarget);
  }

  get $phoneNumberTarget() {
    return $(this.phoneNumberTarget);
  }

  get $mailingAddressTarget() {
    return $(this.mailingAddressTarget);
  }

  get $sosPointOfContactTarget() {
    return $(this.element).find('[name="user[sos_point_of_contact]"]');
  }

  get $agencyRepresentativeFieldsContainerTarget() {
    return $(this.agencyRepresentativeFieldsContainerTarget);
  }

  get $reflectiveCoachFieldsContainerTarget() {
    return $(this.reflectiveCoachFieldsContainerTarget);
  }
}
