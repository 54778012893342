// <select
//    data-controller="selectize"
// >
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // There's a slightly issue when seq_sortable_controller.
    // It happens after dragging because it triggers `disconnect` and `connect` back.
    if (this.valueBeforeDisconnect) {
      this.$element.val(this.valueBeforeDisconnect);
    }

    this.$element.selectize({
      copyClassesToDropdown: false
    });
  }

  disconnect() {
    this.valueBeforeDisconnect = this.$element.val();
    if (this.element.selectize) {
      this.element.selectize.destroy();
    }
  }

  get $element() {
    return $(this.element);
  }
}
