import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "video" ]

  connect() {
    var player = videojs(this.videoTarget);

    // Don't play otherwise it will play automatically after revisit
    // player.play();
  }

  disconnect() {
    var player = videojs(this.videoTarget);
    player.dispose();
  }
}
