import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "form", "watchedComplete" ]

  connect() {
    let self = this;

    if (typeof(Storage) === "undefined") {
      console.log("No web storage Support.");
    }
    self.localStorage = window.localStorage;

    // Read and load session back
    let sessionData = self.read();
    let jsonSession = JSON.parse(sessionData);
    if (jsonSession) {
      self.scormAPI.loadFromJSON(jsonSession);
    }

    // Scorm 1.2
    window.API.on("LMSSetValue.cmi.core.lesson_status", function(CMIElement, value) {
      if (CMIElement == "cmi.core.lesson_status" && value == "completed") {
        self.submitHandler();
      }
    });

    // Scorm 2004
    window.API_1484_11.on("SetValue.cmi.completion_status", function(CMIElement, value) {
      if (CMIElement == "cmi.completion_status" && value == "completed") {
        self.submitHandler();
      }
    });

    // Write session when unload
    $(document).on("turbolinks:unload.scorm_video_form", function() {
      var simplifiedObject = self.scormAPI.cmi.toJSON();
      self.write(JSON.stringify(simplifiedObject));
    });
  }

  disconnect() {
    this.scormAPI.reset();
    this.$watchedCompleteTarget.val("false");
    $(document).off("turbolinks:unload.scorm_video_form");
  }

  submitHandler() {
    let $form = $(this.formTarget);

    // Mark watching video has completed
    this.$watchedCompleteTarget.val("true");

    // Submit the form
    window.setTimeout(function() {
      $form.submit();
    }, 1000);
  }

  write(value) {
    this.localStorage.setItem(this.storageIdentifier, value);
  }

  read() {
    return this.localStorage.getItem(this.storageIdentifier);
  }

  get storageIdentifier() {
    return this.data.get("storageIdentifier");
  }

  get $watchedCompleteTarget() {
    return $(this.watchedCompleteTarget);
  };

  get scormVersion() {
    return this.data.get("scormVersion");
  }

  get scormAPI() {
    if (this.scormVersion == "1.2") {
      return window.API;
    } else {
      return window.API_1484_11;
    }
  }
}
