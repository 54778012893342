import { Controller } from "@hotwired/stimulus";

// Inspired by https://github.com/stimulus-components/stimulus-remote-rails/blob/master/src/index.ts
export default class extends Controller {
  static targets = [ "autoFocus" ]

  replace(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;
    this.element.outerHTML = xhr.response;

    // $(this.autoFocusTarget).focus() doesn't work.
    let autoFocusTargetId = this.autoFocusTarget.getAttribute("id");
    $(`#${autoFocusTargetId}`).focus();
  }
}
