// <div
//    data-controller="fancy-message"
//    data-fancy-message-type="success"
//    data-fancy-message-text="Message"
// >
// </div>
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    new App.FancyMessage(this.type, this.text).display();
  }

  get type() {
    return this.data.get('type');
  }

  get text() {
    return this.data.get('text');
  }
}
