import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button", "tooltip"]

  connect() {
    let self = this;

    self.$inputTarget.on("click", function() {
      $(this).select();
    });

    self.$buttonTarget.on("click", function() {
      // Select the text field
      self.inputTarget.select();
      self.inputTarget.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(self.inputTarget.value);

      // Show tooltip
      self.tooltipTarget.innerHTML = "Copied!";
    });

    self.$buttonTarget.on("mouseout", function() {
      self.tooltipTarget.innerHTML = "Copy to clipboard";
    });
  }

  get $inputTarget() {
    return $(this.inputTarget);
  }

  get $buttonTarget() {
    return $(this.buttonTarget);
  }
}
