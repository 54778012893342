class FancyMessage {
  constructor(type, message) {
    this.type = type;
    this.message = message;
  }

  display() {
    $.notify({
      icon: "notifications",
      message: this.message
    }, {
      type: this._normalizedType(),
      timer: 2000,
      placement: {
        from: "top",
        align: "right"
      }
    });
  }

  _normalizedType() {
    let mappings = {
      "info":    "info",
      "notice":  "warning",
      "success": "success",
      "error":   "danger",
      "alert":   "danger",
    }

    return mappings[this.type];
  }
}

export default FancyMessage;
